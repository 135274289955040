import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import Carousel from "../components/Carousel"

const Laowa24T8 = ({ data, location }) => {
  const url = `https://speedcambrasil.com.br${location.pathname}`

  let carouselImages = []
  data.allCarouselInnovision.edges.forEach(element => {
    console.log(element["node"])
    if (element["node"].childImageSharp !== null) {
      console.log("null")
      carouselImages.push({ img: getImage(element["node"]) })
    }
  })

  return (
    <>
      <Metatags
        title="Speedcam Brasil - Laowa 24 T8"
        description="Speed Cam - Rental House"
        url={url}
        image="https://speedcambrasil.com.br/logo.png"
        keywords="speed cam, rental, Phanton, Alexa Mini, Alexa 35, Innovision HD, Innovision HR, Cinemagic Revolution, Laowa 12, Laowa 24"
        language="pt-br"
      />
      <NavBar />
      <div className="container mx-auto text-center">
        <h1 className="text-4xl m-4 md:text-6xl font-semibold text-sc-yellow">
          <i>Laowa 24mm T8 2X Macro Pro2be</i>
        </h1>
        <div className="h-20" />
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-1" />
          <div className="col-span-10">
            <Carousel picts={carouselImages} />
          </div>
        </div>
      </div>
      <div className="h-10" />
      <div className="container mx-auto text-xl m-4">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-1" />
          <div className="col-span-10">
            <p>
              Laowa 24mm T8 2X Pro2be - a combinação perfeita de versatilidade e
              inovação. O kit de lentes vem com 3 lentes distintas, incluindo a
              visualização exclusiva de inclinação de 35 graus. O Pro2be oferece
              abertura máxima T8, ampliação de 2X e distância à prova d'água de
              36,6 cm, combinando perfeitamente com suas câmeras de alta
              velocidade e sistema de controle de movimento.
            </p>
            <p>
              Os distintos corpos de lente tubulares e torcidos de forma
              inteligente oferecem flexibilidade máxima para explorar novas
              perspectivas e novos patamares. É a solução completa para capturar
              uma ampla variedade de fotos, desde close-ups extremos até fotos
              cinematográficas de cima e plongee, no nível máximo de experiência
              do usuário.
            </p>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <div className="container mx-auto text-xl">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-3" />
          <div className="col-span-8">
            <div>
              <h1 className="my-2">Principais características:</h1>

              <ul className="list-disc list-inside">
                <li>Lente com encaixe PL formato Full Frame</li>
                <li>Faixa de abertura de f/8 a f/40</li>
                <li>Distância focal de 24mm</li>
                <li>Ângulo de vista diagonal de 85º</li>
                <li>Campo de imagem de 43,2mm</li>
                <li>Estrutura da lente</li>
                <ul class="pl-12">
                  <li>Visualização Direta - 33 elementos em 24 grupos</li>
                  <li>Visualização de 35° - 33 elementos em 24 grupos</li>
                  <li>
                    Visualização de Periscópio - 34 elementos em 25 grupos
                  </li>
                </ul>
                <li>10 Lâminas de abertura</li>
                <li>foco 2:1 a infinito</li>
                <li>distância de trabalho mínima: 4mm</li>
                <li>magnigicação máxima de 2x</li>
                <li>Foco manual</li>
                <li>Dimensões</li>
                <ul class="pl-12">
                  <li>Visualização Direta - 30,4 x 507mm</li>
                  <li>Visualização de 35° - 30,4 x 500mm</li>
                  <li>Visualização de Periscópio - 30,4 x 500mm</li>
                </ul>
                <li>Peso</li>
                <ul class="pl-12">
                  <li>Visualização Direta - 1038g</li>
                  <li>Visualização de 35° - 1038g</li>
                  <li>Visualização de Periscópio - 1068g</li>
                </ul>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="h-12" />

      <div className="h-10" />

      <Footer />
    </>
  )
}

export default Laowa24T8

export const query = graphql`
  {
    allCarouselInnovision: allFile(
      filter: { relativePath: { regex: "/laowa-24-t8/" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1536)
          }
        }
      }
    }
  }
`
